import React, {
  memo, useState, useEffect, useRef,
} from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import AuthHelper from '../../utils/AuthHelper';
import Footer from '../Layout/Footer';
import IdealTimerModal from '../common/Modal/IdealTimerModal';
import Toast from '../common/Toast/Toast';
import { setAuthToken } from '../../redux/slices/userDataSlice';
import { setSearchKey, setSearchloading } from '../../redux/slices/searchSlice';
import useTokenExpiration from '../../utils/useTokenExpiration';

function RequireAuth({ children }) {
  const timeout = 3000000;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isIdle, setIsIdle] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [modalTimer, setModalTimer] = useState(null);
  const idleTimerRef = useRef(null);
  const loc = useLocation();
  const { searchKey } = useSelector((state) => state.search) || {};
  const location = loc?.pathname;
  useTokenExpiration();
  const handlelogout = () => {
    setIsIdle(false);
    AuthHelper.clearAll();
    dispatch(setAuthToken(false));
    navigate('/login');
  };
  useEffect(() => {
    // Clear the search key and loading state when location changes
    if (location !== '/search' && searchKey && searchKey !== '') {
      dispatch(setSearchKey(''));
      dispatch(setSearchloading(false));
    }
  }, [location, searchKey, dispatch]);

  useEffect(() => {
    let logoutTimer;

    if (isIdle && showWarning) {
      logoutTimer = setTimeout(() => {
        setIsIdle(false);
        AuthHelper.clearAll();
        dispatch(setAuthToken(false));
        navigate('/login');
      }, 10000); // 10 seconds for immediate logout warning
    }

    return () => {
      clearTimeout(logoutTimer);
    };
  }, [isIdle, showWarning, navigate, dispatch]);

  useEffect(() => {
    let timer;

    if (showWarning) {
      timer = setTimeout(() => {
        handlelogout();
      }, 5 * 60 * 1000); // 5 minutes in milliseconds

      setModalTimer(timer);
    }

    return () => {
      clearTimeout(timer); // Clear the timer if the component unmounts or if showWarning changes
    };
  }, [showWarning]);

  const handleOnIdle = () => {
    setShowWarning(true);
    setIsIdle(true);
  };

  const handleOnActive = () => {
    setIsIdle(false);
  };

  const handleOnAction = () => {
    setIsIdle(false);
  };

  const handleKeepsIn = () => {
    setShowWarning(false);
    setIsIdle(false);
    clearTimeout(modalTimer); // Clear the modal timer if user keeps active
  };
  if (!AuthHelper.getAuthToken()) {
    return <Navigate to="/login" />;
  }
  if (jwtDecode(AuthHelper.getAuthToken()).exp < Date.now() / 1000) {
    localStorage.clear();
    Toast('Session timeout. Please login again.', 'error');
    return <Navigate to="/login" />;
  }
  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={timeout}
        onIdle={handleOnIdle}
        onActive={handleOnActive}
        onAction={handleOnAction}
      />
      <>
        <div
          className={
            !(
              location === '/plans'
              || location === '/renew-plan'
              || location === '/payment'
              || location === '/payment-success'
            )
              ? 'content-area'
              : ''
          }
        >
          {children}
          {!(
            location === '/plans'
            || location === '/renew-plan'
            || location === '/payment'
            || location === '/payment-success'
          ) && <Footer />}
        </div>
        <IdealTimerModal show={showWarning} onHide={handleKeepsIn} logout={handlelogout} />
      </>
    </>
  );
}

export default memo(RequireAuth);
