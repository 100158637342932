import { toast as toasts } from 'react-toastify';

const Toast = (message, type, textcolor) => {
  console.log('message', message);
  if (textcolor) {
    toasts(message, {
      hideProgressBar: true,
      theme: 'colored',
      autoClose: 5000,
      type,
      style: {
        color: textcolor,
      },
    });
  } else {
    toasts(message, {
      hideProgressBar: true,
      theme: 'colored',
      autoClose: 2000,
      type,
    });
  }
};
export default Toast;
