/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-useless-fragment  */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Collapse from 'react-bootstrap/Collapse';
import { Spinner, Button, Modal } from 'react-bootstrap';
import editimg from '../../assests/images/edit.svg';
import ArrowRight from '../../assests/images/arrow-right.svg';
import Service from '../../utils/api/services/service';
import {
  setInfoBinder,
  setInfoBinderloading,
  setBinderLoading,
  setBinderData,
  setPrintSuccessMsg,
  setPrintKeyContact,
  setInfoLoad,
  setUserInfo,
} from '../../redux/slices/infoBinderSlice';
import { setCategoryTitle } from '../../redux/slices/categorySlice';
import useutils from '../../utils/index';
import {
  setIsRole,
  setMyroleCount,
  setTestatorManageAccess,
  setPeriodicReminder,
  setTestatorProfile,
  setSelectedRole,
} from '../../redux/slices/roleDataSlice';
import { setOpen } from '../../redux/slices/sideBarDataSlice';
import Logout from './Logout';
import Permissions from '../../utils/api/services/permissionService';
import InfoModal from '../common/Modal/infobinderModal';
import HelpModal from '../common/Modal/HelpModal';
import Logo from '../common/Logo';
import AuthHelper from '../../utils/AuthHelper';
import Profile from './Header/Profile';
import Search from './Header/Search';
import RoleMenuList from './Header/RoleMenuList';
import MenuList from './Header/MenuList';
import Plan from './Header/Plan';
import Subscriptions from '../../utils/api/services/subscriptionService';
import PrintSuccessNotif from '../common/Modal/PrintSuccessNoti';
import PrintNotif from '../common/Modal/PrintNoti';
import Contacts from '../../utils/api/services/keyContactService';
import User from '../../utils/api/services/userService';

export default function SidebarMenu() {
  const { pascalText } = useutils();
  const location = useLocation();
  const dispatch = useDispatch();
  const getname = (name) => pascalText(name);
  const [show, setshow] = useState(false);
  const {
    isRole,
    selectedRole,
    isAfterDeathval,
    testatorProfile,
    testatorManageAccess,
    periodicReminderPermission,
  } = useSelector((state) => state.roles) || {};
  const { printSuccessMsg, binderLoading } = useSelector((state) => state.infoBinder) || {};
  const navigate = useNavigate();
  const [isPlanExp, setIsPlanExp] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [printshow, setprintshow] = useState(null);
  const [RolemenuList, setMenuRoleList] = useState([]);
  const [myRoleLoading, setMyRoleLoading] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [collapseRole, setCollapseRole] = useState(true);
  const [isHelp, setHelp] = useState(false);
  const [inoUrl, setinfoUrl] = useState();
  const [showPlanExpire, setShowPlanExpire] = useState(false);
  const [isRecurring, setRecurring] = useState(0);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [delayInMilliseconds, setDelayInMilliSeconds] = useState(900000);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [subscribedelayInMilliseconds, setSubscribeDelayInMilliSeconds] = useState(1800000);
  const userInfo = AuthHelper.getUserInfo();

  const getLocation = (sub) => {
    const locationSelected = [
      `/categoryList/${sub?.id}/${sub?.uuid}`,
      `/category/${sub?.id}/${sub?.uuid}`,
      `/estateplanningBanner/${sub?.id}/${sub?.uuid}`,
      `/assetsBanner/${sub?.id}/${sub?.uuid}`,
      `/catgoryListBox/${sub?.id}/${sub?.uuid}`,
      `/liabiltiesBanner/${sub?.id}/${sub?.uuid}`,
      `/fortheHomeBanner/${sub?.id}/${sub?.uuid}`,
      `/importantDocumentsBanner/${sub?.id}/${sub?.uuid}`,
      `/specialItemsBanner/${sub?.id}/${sub?.uuid}`,
      `/keyContacts/${sub?.id}/${sub?.uuid}`,
    ];
    return !!locationSelected?.includes(location?.pathname);
  };

  const infoBinderHandler = (url) => {
    setCollapse(!collapse);
    localStorage.removeItem('testatorId');
    dispatch(setIsRole(false));
    dispatch(setTestatorManageAccess(false));
    dispatch(setPeriodicReminder(false));
    dispatch(setTestatorProfile(false));
    dispatch(setSelectedRole(undefined));
    setinfoUrl(url);
  };

  const getBinderData = async () => {
    dispatch(setBinderLoading(true));
    setprintshow(true);
    const params = {
      export: true,
    };
    try {
      const response = await Service.binderPrint();
      const keyContactResponse = await Contacts.getContactList(
        0,
        10,
        '',
        '',
        '',
        params,
      );
      if (response.status === 200 && keyContactResponse.status === 200) {
        dispatch(setBinderData(response.data));
        dispatch(setPrintKeyContact(keyContactResponse.data?.keyContacts));
        dispatch(setBinderLoading(false));
        if (
          response.data?.length === 0
          && keyContactResponse.data?.keyContacts?.length === 0
        ) {
          dispatch(setPrintSuccessMsg({ istrue: true, mssg: 0 }));
        } else {
          dispatch(setPrintSuccessMsg({ istrue: true, mssg: 1 }));
        }
        setprintshow(false);
      } else {
        dispatch(setPrintSuccessMsg({ istrue: true, mssg: 2 }));
        dispatch(setBinderLoading(false));
        setprintshow(false);
      }
    } catch (err) {
      dispatch(setPrintSuccessMsg({ istrue: true, mssg: 2 }));
      dispatch(setBinderLoading(false));
      setprintshow(false);
    }
  };

  const getUserInfo = async () => {
    try {
      dispatch(setInfoLoad(true));
      const response = await User.getUserInfo();
      if (response?.status === 200) {
        const { data } = response;
        dispatch(setInfoLoad(false));
        console.log('v=--------------', data);
        dispatch(setUserInfo(data));
      }
    } catch (e) {
      dispatch(setInfoLoad(false));
      console.log(e);
    }
  };
  const hideDrawer = (sidebarname) => {
    dispatch(setOpen(false));
    if (sidebarname === 'Help') {
      setHelp(true);
    }
    if (sidebarname === 'Print Information Binder') {
      getBinderData();
      getUserInfo();
    }
  };
  const getSubListOnclickHandler = (item, subtitle) => {
    if (subtitle === 'myinfo') {
      navigate(`/categoryList/${item?.id}/${item?.uuid}`, {
        state: { category: item },
      });
    } else {
      navigate(`/category/${item?.id}/${item?.uuid}`);
    }
    dispatch(setCategoryTitle(item?.title));

    hideDrawer();
  };

  const getSubList = (item) => (
    <li className="sidemenu-items">
      <a
        className={collapse ? 'collapsed' : 'collapsed-after'}
        data-bs-toggle="collapse"
        href
        aria-expanded={!collapse}
        aria-controls="collapseExample"
        onClick={() => setCollapse(!collapse)}
      >
        <span
          className={
            location?.pathname === '/infoBinder'
              ? 'activespan pointer'
              : 'pointer'
          }
          onClick={() => infoBinderHandler(item.url)}
        >
          <img src={item.img} alt="home" />
          {item.name}
        </span>
        <img src={ArrowRight} alt="arrow-right" className="menu-arrow ms-4" />
      </a>
      <Collapse in={!collapse}>
        <div id="collapseExample">
          {menuList?.length === 0 ? (
            <div className="submenu-outer">
              <span>
                {[1, 2, 3].map((i) => (
                  <Spinner
                    key={i}
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                  />
                ))}
              </span>
            </div>
          ) : (
            <div className="submenu-outer">
              {Array.isArray(menuList)
                && menuList?.map((sub) => (
                  <span className="submenus d-flex" key={sub?.title}>
                    <i
                      className="bi bi-file-text color-blue"
                      style={{
                        color: '#023047',
                        marginRight: '10px',
                        marginTop: '6px',
                      }}
                      color="#023047"
                    />
                    <a
                      href
                      className={
                        getLocation(sub) ? 'active pointer' : 'pointer'
                      }
                      onClick={() => getSubListOnclickHandler(sub, 'myinfo')}
                    >
                      <span>{sub?.title}</span>
                    </a>
                  </span>
                ))}
            </div>
          )}
        </div>
      </Collapse>
    </li>
  );
  const getClassName = (val) => {
    if (val) {
      if (location?.pathname === '/infoBinderRole') {
        return 'collapsed align-items-start activespan';
      }
      return 'collapsed align-items-start';
    }
    if (location?.pathname === '/infoBinderRole') {
      return 'collapsed-after align-items-start activespan';
    }
    return 'collapsed-after align-items-start';
  };
  const infoBinderRoleHandler = (url) => {
    setCollapseRole(!collapseRole);
    setinfoUrl(url);
  };

  const getSubRoleMenuList = (item) => (
    <li className="sidemenu-items me-2">
      <a
        className={collapseRole ? getClassName(true) : getClassName(false)}
        data-bs-toggle="collapse"
        href
        aria-expanded={!collapseRole}
        aria-controls="collapseExample"
        onClick={() => setCollapseRole(!collapseRole)}
      >
        <img src={item.img} alt="home" className="mt-2" />
        <span
          className={
            location?.pathname === '/infoBinderRole'
              ? 'activespan pointer me-3'
              : 'pointer me-3'
          }
          onClick={() => infoBinderRoleHandler(item.url)}
        >
          {`${getname(selectedRole?.firstName)}'s Information Binder`}
        </span>
        <img src={ArrowRight} alt="arrow-right" className="menu-arrow mt-2" />
      </a>
      <Collapse in={!collapseRole}>
        <div id="collapseExample">
          {myRoleLoading ? (
            <div className="submenu-outer">
              <span>
                {[1, 2, 3].map((i) => (
                  <Spinner
                    key={i}
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                  />
                ))}
              </span>
            </div>
          ) : (
            <>
              {' '}
              {RolemenuList?.length === 0 ? (
                <div className="submenu-outer">
                  <span>
                    <i>No data found.</i>
                  </span>
                </div>
              ) : (
                <div className="submenu-outer">
                  {Array.isArray(RolemenuList)
                    && RolemenuList?.map((sub) => (
                      <span className="submenus d-flex" key={sub?.title}>
                        <i
                          className="bi bi-file-text color-blue"
                          style={{
                            color: '#023047',
                            marginRight: '10px',
                            marginTop: '6px',
                          }}
                          color="#023047"
                        />
                        <a
                          href
                          className={
                            location?.pathname
                            === `/category/${sub?.id}/${sub?.uuid}`
                              ? 'active pointer'
                              : 'pointer'
                          }
                          onClick={() => getSubListOnclickHandler(sub, 'myRole')}
                        >
                          <span>{sub?.title}</span>
                        </a>
                      </span>
                    ))}
                </div>
              )}
            </>
          )}
        </div>
      </Collapse>
    </li>
  );
  const getTestatorManageAccessMenuList = (item) => (
    <>
      {testatorManageAccess || periodicReminderPermission ? (
        <li className="sidemenu-items">
          <Link
            onClick={() => hideDrawer()}
            to={item.url}
            className={location?.pathname?.includes(item.url) ? 'active' : ''}
          >

            {item?.name === 'Testator' ? (
              <>
                {testatorManageAccess ? (
                  <>
                    <img src={item?.img} alt="home" />
                    <span>
                      {`${getname(selectedRole?.firstName)}'s Manage Access`}
                    </span>

                  </>
                ) : null}

              </>
            ) : (
              <>
                {periodicReminderPermission
                  ? (
                    <>
                      <img src={item?.img} alt="home" />
                      <span>
                        {`${getname(selectedRole?.firstName)}'s Periodic Review`}
                      </span>

                    </>
                  ) : null}

              </>
            )}
          </Link>
        </li>
      ) : null}
    </>
  );
  const getSubTestatorMenuList = (item) => (
    <>
      {testatorProfile ? (
        <li className="sidemenu-items">
          <Link
            onClick={() => hideDrawer()}
            to={item.url}
            className={location?.pathname?.includes(item.url) ? 'active' : ''}
          >
            <img src={item?.img} alt="home" />
            <span>{`${getname(selectedRole?.firstName)}'s Account`}</span>
          </Link>
        </li>
      ) : null}
    </>
  );
  const onClickPermission = (item) => {
    dispatch(setOpen(false));
    navigate(item?.url);
    setinfoUrl(item?.url);
    setCollapse(true);
    localStorage.removeItem('testatorId');
    dispatch(setIsRole(false));
    dispatch(setSelectedRole(undefined));
    dispatch(setTestatorManageAccess(false));
    dispatch(setPeriodicReminder(false));
    dispatch(setTestatorProfile(false));
  };
  const onClickMenuItem = (item) => {
    dispatch(setSelectedRole(undefined));
    localStorage.removeItem('testatorId');
    dispatch(setIsRole(false));
    dispatch(setSelectedRole(undefined));
    dispatch(setTestatorManageAccess(false));
    dispatch(setPeriodicReminder(false));
    dispatch(setTestatorProfile(false));
    dispatch(setOpen(false));
    navigate(item?.url);
    setinfoUrl(item?.url);
  };
  const getRoleList = async () => {
    try {
      const response = await Permissions.getRoles();
      if (response?.status === 200) {
        const { data } = response;
        let permission = [];
        let revoke = 0;
        const getvalues = {
          manageaccess: testatorManageAccess,
          profile: testatorProfile,
          isRole,
        };
        if (isRole) {
          const checkPermission = data?.filter(
            (item) => item?.userId === selectedRole?.userId,
          );
          if (checkPermission.length > 0) {
            permission = checkPermission[0];
            dispatch(
              setTestatorManageAccess(permission?.manageAccessPermission),
            );
            dispatch(
              setPeriodicReminder(permission?.periodicReminderPermission),
            );
            dispatch(setTestatorProfile(permission?.profilePermission));
          } else {
            revoke = 1;
            dispatch(setTestatorManageAccess(false));
            dispatch(setPeriodicReminder(false));
            dispatch(setTestatorProfile(false));
            dispatch(setIsRole(false));
            dispatch(setSelectedRole(undefined));
          }
          if (
            getvalues?.manageaccess !== permission?.manageAccessPermission
            || getvalues?.profile !== permission?.profilePermission
            || revoke === 1
          ) {
            navigate('/dashboard');
          }
        }
        dispatch(
          setMyroleCount(
            data?.filter((item) => item.permissionStatus === 1)?.length,
          ),
        );
      }
    } catch (e) {
      dispatch(setMyroleCount(0));
    }
  };
  const getSubmenuList = async () => {
    console.log('here------------sdddddddddd');
    try {
      dispatch(setInfoBinderloading(true));
      const response = await Service.getSubmenu();
      if (response?.data) {
        setMenuList(response?.data?.result);
        dispatch(setInfoBinder(response?.data?.result));
        dispatch(setInfoBinderloading(false));
      }
    } catch (e) {
      console.log('response', e);
      dispatch(setInfoBinderloading(false));
    }
  };
  const getSubmenuRoleList = async () => {
    try {
      dispatch(setInfoBinderloading(true));
      setMyRoleLoading(true);
      const params = {
        viewUserId: selectedRole?.userId,
        isAfterDeath: isAfterDeathval,
      };
      const response = await Service.getSubmenu(params);
      if (response?.data) {
        setMenuRoleList(response?.data?.result);
        dispatch(setInfoBinder(response?.data?.result));
        dispatch(setInfoBinderloading(false));
        setMyRoleLoading(false);
      }
    } catch (e) {
      console.log('response', e);
      setMyRoleLoading(false);
      dispatch(setInfoBinderloading(false));
    }
  };
  // useEffects
  useEffect(() => {
    getRoleList();
    getSubmenuList();
  }, []);

  useEffect(() => {
    if (inoUrl) {
      navigate(inoUrl);
      getSubmenuList();
    }
  }, [collapse]);
  useEffect(() => {
    if (isRole) {
      getSubmenuRoleList();
    }
  }, [isRole]);
  const viewPlan = async () => {
    const isPlan = localStorage.getItem('PlanExpire');
    const isSubscribeModal = localStorage.getItem('SubscribeModal');
    try {
      const response = await Subscriptions.viewPlan();
      if (response?.data?.reminder === 1) {
        setShowPlanExpire(!isPlan);
        setRecurring(response?.data?.recurring);
      }
      setTimeout(async () => {
        if (response?.status === 200) {
          if (response.data.planType === 1) {
            if (!show && !isHelp && !isSubscribeModal) {
              setShowSubscribeModal(true);
            }
          }
        }
      }, subscribedelayInMilliseconds);
    } catch (e) {
      console.log('d7g', e);
    }
  };
  useEffect(() => {
    viewPlan();
  }, [subscribedelayInMilliseconds]);

  useEffect(() => {
    const IsProfileModal = localStorage.getItem('ProfileModal');
    setTimeout(() => {
      if (
        !userInfo?.recoveryPhone
        || !userInfo?.recoveryEmail
        || !userInfo?.phoneNumber
      ) {
        if (!show && !isHelp && !IsProfileModal) {
          setShowProfileModal(true);
        }
      }
    }, delayInMilliseconds);
  }, [delayInMilliseconds]);

  const skipHandler = () => {
    localStorage.setItem('ProfileModal', true);
    setShowProfileModal(false);
  };
  const skipPlanExpire = () => {
    setShowProfileModal(false);
    localStorage.setItem('PlanExpire', true);
    setShowPlanExpire(false);
  };
  const skipSubscribeHandler = () => {
    localStorage.setItem('SubscribeModal', true);
    setShowSubscribeModal(false);
  };
  return (
    <div className="sidebar-menu">
      <div className="logobox d-flex justify-content-between">
        <h5 className="fw-bold">
          <Logo />
        </h5>
        {' '}
        <a href onClick={() => navigate('/personalInfo')}>
          <img src={editimg} alt="edit" width="20" />
        </a>
      </div>
      <Profile />
      <div className="d-flex flex-column justify-content-center mb-2">
        {isRole && (
          <Button className="btn bg-b w-100">Trusted Person View</Button>
        )}
        {!isPlanExp || isRole ? (
          <Search />
        ) : (
          <div className="acc pointer " onClick={() => navigate('/plans')}>
            Account expired
          </div>
        )}
      </div>
      {isRole ? (
        <RoleMenuList
          getSubList={getSubList}
          getSubRoleMenuList={getSubRoleMenuList}
          onClickPermission={onClickPermission}
          hideDrawer={hideDrawer}
          getTestatorManageAccessMenuList={getTestatorManageAccessMenuList}
          getSubTestatorMenuList={getSubTestatorMenuList}
          onClickMenuItem={onClickMenuItem}
        />
      ) : (
        <MenuList
          getSubList={getSubList}
          onClickPermission={onClickPermission}
          hideDrawer={hideDrawer}
          isloading={printSuccessMsg?.istrue || binderLoading}
          isPlanExp={isPlanExp}
          setIsPlanExp={setIsPlanExp}
        />
      )}
      <Plan />
      {show && <InfoModal setShow={setshow} />}
      {isHelp && <HelpModal setShow={setHelp} />}
      <Modal
        show={showProfileModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setShowProfileModal(false);
        }}
      >
        <Modal.Header closeButton className="p-4">
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="text-prmary fw-bold my-0">Complete your profile</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Would you like to complete your profile now? Providing your phone
            number and recovery email makes your information more secure.
          </h6>
        </Modal.Body>
        <Modal.Footer
          style={{ paddingRight: '2rem', justifyContent: 'space-between' }}
        >
          <div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setDelayInMilliSeconds(1200000);
                setShowProfileModal(false);
              }}
            >
              Remind me later
            </span>
          </div>
          <div>
            <span
              style={{ cursor: 'pointer', marginRight: '8px' }}
              onClick={skipHandler}
            >
              Skip
            </span>
            <Button
              style={{ cursor: 'pointer' }}
              className="btn btn-primary"
              onClick={() => {
                navigate('/personalInfo', {
                  state: {
                    isLoggedUser: true,
                  },
                });
                setShowProfileModal(false);
              }}
            >
              Verify
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showSubscribeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setShowSubscribeModal(false);
        }}
      >
        <Modal.Header closeButton className="p-4">
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="text-prmary fw-bold my-0">Subscribe</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Would you like to subscribe now? Gain peace of mind knowing your
            loved ones have easy and secure access to essential information all
            in one place.
          </h6>
        </Modal.Body>
        <Modal.Footer
          style={{ paddingRight: '2rem', justifyContent: 'space-between' }}
        >
          <div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSubscribeDelayInMilliSeconds(3600000);
                setShowSubscribeModal(false);
              }}
            >
              Remind me later
            </span>
          </div>
          <div>
            <span
              style={{ cursor: 'pointer', marginRight: '8px' }}
              onClick={skipSubscribeHandler}
            >
              Skip
            </span>
            <Button
              style={{ cursor: 'pointer' }}
              className="btn btn-primary"
              onClick={() => {
                navigate('/plans');
                setShowSubscribeModal(false);
              }}
            >
              Subscribe
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showPlanExpire}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setShowPlanExpire(false);
        }}
      >
        <Modal.Header closeButton className="p-4">
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="text-prmary fw-bold my-0">
              Renew your subscription
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isRecurring === 0 ? (
            <h6>
              This is a friendly reminder that your subscription will end soon.
              Renew your subscription to gain peace of mind knowing your loved
              ones have easy and secure access to essential information all in
              one place.
            </h6>
          ) : (
            <h6>
              This is a friendly reminder that.Your subscription will
              automattically renew soon
            </h6>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{ paddingRight: '2rem', justifyContent: 'space-between' }}
        >
          <div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowPlanExpire(false);
              }}
            >
              Remind me later
            </span>
          </div>
          <div>
            <span
              style={{ cursor: 'pointer', marginRight: '8px' }}
              // onClick={() => setShowProfileModal(false)}
              onClick={skipPlanExpire}
            >
              Skip
            </span>
            <Button
              style={{ cursor: 'pointer' }}
              className="btn btn-primary"
              onClick={() => {
                navigate('/plans');
                setShowSubscribeModal(false);
                setShowPlanExpire(false);
              }}
            >
              Upgrade
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Logout />
      {printshow && <PrintNotif setShow={setprintshow} />}
      {printSuccessMsg?.istrue ? (
        <PrintSuccessNotif printSuccessMsg={printSuccessMsg} />
      ) : null}
    </div>
  );
}
