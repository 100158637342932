import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import SearchIcon from '../../../assests/images/search.svg';
import { setSearchKey } from '../../../redux/slices/searchSlice';

export default function Search() {
  const dispatch = useDispatch();
  const { searchloading, searchKey } = useSelector((state) => state.search) || {};
  const navigate = useNavigate();
  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      navigate('/search', { state: { searchKey } });
    }
  };

  return (
    <div className="search-boxess mt-4">
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control py-1"
          placeholder="Search"
          aria-label="Search"
          aria-describedby="basic-addon2"
          onChange={(e) => dispatch(setSearchKey(e.target.value))}
          onKeyPress={handleSearch}
          value={searchKey}
        />
        <a
          onClick={() => navigate('/search', { state: { searchKey } })}
          href
          className="input-group-text px-2"
          id="basic-addon2"
        >
          {searchloading ? (
            <Spinner size="sm" />
          ) : (
            <img src={SearchIcon} alt="search" width="20" />
          )}
        </a>
      </div>
    </div>
  );
}
