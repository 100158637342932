import { useEffect, useRef } from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import AuthHelper from './AuthHelper'; // Adjust this import according to your project structure
import Toast from '../components/common/Toast/Toast';
import { setAuthToken } from '../redux/slices/userDataSlice';

const useTokenExpiration = () => {
  const warningShownRef = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = AuthHelper.getAuthToken();
      const handleTokenExpiration = () => {
        AuthHelper.clearAll();
        dispatch(setAuthToken(false));
        window.location.href = '/login';
      };
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // current time in seconds
        const expirationTime = decodedToken.exp;

        if (expirationTime < currentTime) {
          // Token has expired
          handleTokenExpiration();
        } else if (expirationTime - currentTime < 5 * 60 && !warningShownRef.current) {
          // Token is about to expire in less than 5 minutes
          warningShownRef.current = true;
          Toast('For security, all sessions expire in 60 minutes. Your session will expire in the next 5 minutes. Please re-login to continue.', 'warning', 'black');
        } else if (expirationTime - currentTime >= 5 * 60) {
          // Reset warning when there's still more than 5 minutes before expiration
          warningShownRef.current = false;
        }
      }
    };

    // Check token expiration every minute
    const intervalId = setInterval(checkTokenExpiration, 60 * 1000);

    // Check immediately on mount
    checkTokenExpiration();

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
};

export default useTokenExpiration;
